@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'logo';
  src: local('Quicksand-Medium'), url(./assets/font/Quicksand/Quicksand-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'headline';
  src: local('Alata'), url(./assets/font/Alata/Alata-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'bold';
  src: local('Lato-Bold'), url(./assets/font/Lato/Lato-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'light';
  src: local('Lato-Light'), url(./assets/font/Lato/Lato-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'regular';
  src: local('Lato-Regular'), url(./assets/font/Lato/Lato-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'thin';
  src: local('Lato-Thin'), url(./assets/font/Lato/Lato-Thin.ttf) format('truetype');
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

@media (max-width: 720px) {
  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FBD87D;
  border-radius: 5px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.top-shadow {
  box-shadow: 0px 3px 5px rgba(0,0,0,0.5);
}