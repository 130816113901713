.btn {
    overflow: hidden;
  position: relative;
}
  
.ripple {
  display: block;
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  
  width: 100px;
  height: 100px;

  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;

  animation: ripple 1s;
  opacity: 0;
  pointer-events: none;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(10);
  }
}